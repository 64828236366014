import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'
import devices from './app/devices'
import sites from './app/sites'
import alarms from './app/alarms'
import user from './app/user'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    appConfig: appConfigStoreModule,
    app,
    sites,
    devices,
    alarms,
    user,
  },
})
